import React, { useState } from 'react';
import { Copy, Check } from 'lucide-react';

const PasswordGenerator = () => {
  const [password, setPassword] = useState('');
  const [length, setLength] = useState(12);
  const [includeUppercase, setIncludeUppercase] = useState(true);
  const [includeLowercase, setIncludeLowercase] = useState(true);
  const [includeNumbers, setIncludeNumbers] = useState(true);
  const [includeSymbols, setIncludeSymbols] = useState(true);
  const [isPronounceable, setIsPronounceable] = useState(false);
  const [includeHyphens, setIncludeHyphens] = useState(false);
  const [sectionLength, setSectionLength] = useState(4);
  const [copied, setCopied] = useState(false);

  const generatePassword = () => {
    let newPassword = '';
    if (isPronounceable) {
      newPassword = generatePronounceablePassword();
    } else {
      let charset = '';
      if (includeUppercase) charset += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      if (includeLowercase) charset += 'abcdefghijklmnopqrstuvwxyz';
      if (includeNumbers) charset += '0123456789';
      if (includeSymbols) charset += '!@#$%^&*()_+~`|}{[]:;?><,./-=';

      for (let i = 0; i < length; i++) {
        newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
      }
    }

    if (includeHyphens) {
      newPassword = insertHyphens(newPassword);
    }

    setPassword(newPassword);
  };

  const generatePronounceablePassword = () => {
    const consonants = 'bcdfghjklmnpqrstvwxyz';
    const vowels = 'aeiou';
    let password = '';
    
    while (password.length < length) {
      password += consonants[Math.floor(Math.random() * consonants.length)];
      if (password.length < length) {
        password += vowels[Math.floor(Math.random() * vowels.length)];
      }
    }

    return password.slice(0, length);
  };

  const insertHyphens = (pwd) => {
    let hyphenatedPwd = '';
    for (let i = 0; i < pwd.length; i++) {
      if (i > 0 && i % sectionLength === 0 && i !== pwd.length) {
        hyphenatedPwd += '-';
      }
      hyphenatedPwd += pwd[i];
    }
    return hyphenatedPwd;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(password);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-gray-100 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4 text-gray-800">Random Password Generator</h1>
      <div className="mb-4">
        <label className="block mb-2 text-gray-700">Password Length: {length}</label>
        <input
          type="range"
          min="8"
          max="30"
          value={length}
          onChange={(e) => setLength(Number(e.target.value))}
          className="w-full"
        />
      </div>
      <div className="space-y-2 mb-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="uppercase"
            checked={includeUppercase}
            onChange={(e) => setIncludeUppercase(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="uppercase" className="text-gray-700">Include Uppercase</label>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="lowercase"
            checked={includeLowercase}
            onChange={(e) => setIncludeLowercase(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="lowercase" className="text-gray-700">Include Lowercase</label>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="numbers"
            checked={includeNumbers}
            onChange={(e) => setIncludeNumbers(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="numbers" className="text-gray-700">Include Numbers</label>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="symbols"
            checked={includeSymbols}
            onChange={(e) => setIncludeSymbols(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="symbols" className="text-gray-700">Include Symbols</label>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="pronounceable"
            checked={isPronounceable}
            onChange={(e) => setIsPronounceable(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="pronounceable" className="text-gray-700">Pronounceable Password</label>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="hyphens"
            checked={includeHyphens}
            onChange={(e) => setIncludeHyphens(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="hyphens" className="text-gray-700">Include Hyphens</label>
        </div>
        {includeHyphens && (
          <div className="ml-6">
            <label className="block mb-2 text-gray-700">Section Length: {sectionLength}</label>
            <input
              type="range"
              min="2"
              max="8"
              value={sectionLength}
              onChange={(e) => setSectionLength(Number(e.target.value))}
              className="w-full"
            />
          </div>
        )}
      </div>
      <button 
        onClick={generatePassword} 
        className="w-full mb-4 bg-gray-700 hover:bg-gray-600 text-white py-2 px-4 rounded"
      >
        Generate Random Password
      </button>
      <div className="flex">
        <input 
          type="text" 
          value={password} 
          readOnly 
          className="flex-grow bg-white text-gray-800 p-2 rounded-l"
        />
        <button 
          onClick={copyToClipboard} 
          className="bg-gray-600 hover:bg-gray-500 text-white p-2 rounded-r"
          title={copied ? 'Copied!' : 'Copy to clipboard'}
        >
          {copied ? <Check size={16} /> : <Copy size={16} />}
        </button>
      </div>
    </div>
  );
};

export default PasswordGenerator;